import { NavLink, Outlet } from "react-router-dom";

const Setting = () => {
    return (
        <div>
            <h1>Setting</h1>
        </div>
    )
}

export default Setting;