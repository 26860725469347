import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from "@tanstack/react-query";
import { useInView } from "react-intersection-observer";

// Firebase
import { db, makeRequest } from '../api/firebase';
const collectionShorts = "shorts"
const collectionUsers = "users"
const perPage = 8

// // REST API
// import { makeRequest } from '../api/restAPI';
// const db = `http://192.168.88.111:4000`
// const collectionShorts = "shorts"
// const collectionUsers = "users"
// const perPage = 8


const ShortURL = () => {
    const [newURL, setNewURL] = useState("");
    const [newShortURL, setNewShortURL] = useState("");
    const { ref, inView } = useInView();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (inView) fetchNextPage();
    }, [inView])


    // previousPage/hasNextPage = true if getPreviousPageParam/getNextPageParam is not undefined
    const { data: dataShorts, isPending: isPendingShorts, isFetchingNextPage, hasNextPage, fetchNextPage } = useInfiniteQuery({
        queryKey: ["shorts", "infinite"],
        queryFn: ({ pageParam = 1 }) => { return makeRequest(db, collectionShorts, "GET", { "_page": pageParam, "_per_page": perPage, "data": dataShorts }) },
        keepPreviousData: true,
        getNextPageParam: (snapshots) => { return snapshots.next },
        // getPreviousPageParam: (snapshots) => { return snapshots.prev },
        refetchIntervalInBackground: false,
        initialPageParams: 1,
        refetchOnWindowFocus: false
    })

    const { data: dataUsers, isPending: isPendingUsers } = useQuery({
        queryKey: ["users"],
        queryFn: () => { return makeRequest(db, collectionUsers, "GET") },
        enabled: !isPendingShorts,
        staleTime: 100 * 60 * 1000 // 100 minutes
    })

    function isValidURL(URL) {
        // Regular expression to validate URL
        const urlRegex = /^(http|https):\/\/[^ "]+$/;
        return urlRegex.test(URL);
    }

    const addShortMutation = useMutation({
        mutationFn: () => {
            // check if the URL is valid
            if (!isValidURL(newURL)) { return Promise.reject("Invalid URL") }

            // check if the short URL is already taken
            let newShort = newShortURL
            if (!newShort) {
                newShort = Math.random().toString(36).substring(7)
                setNewShortURL(newShort)
            }

            // add the short URL to the database
            return makeRequest(db, collectionShorts, "POST", { id: newShort, url: newURL, uuid: "Kv6vvJmo4je2INLopgX11xA5Gbv1" })
        },
        onSuccess: () => {
            setNewURL("")    // clear the input field
            setNewShortURL("")    // clear the input field
            return queryClient.invalidateQueries(["shorts"])
        }
    })

    const deleteShortMutation = useMutation({
        mutationFn: id => {
            return makeRequest(db, collectionShorts, "DELETE", { id: id });
        },
        onSuccess: () => {
            return queryClient.invalidateQueries(["shorts"])
        }
    })

    if (isPendingShorts) { return <div>Loading...</div> }

    return (
        <div className="px-8 py-8">
            <h1 className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900 capitalize py-3">Short URL Infinite</h1>
            <form onSubmit={e => e.preventDefault()}>
                <input className="w-full my-2 py-3 border-2 border-green-300 rounded-lg text-gray-700 focus:outline-none focus:border-blue-300" type="url" value={newURL} onChange={(e) => setNewURL(e.target.value)} placeholder="Enter URL to shorten" pattern="https?://.+" required />
                <input className="w-full my-2 py-3 border-2 border-green-300 rounded-lg text-gray-700 focus:outline-none focus:border-blue-300" type="text" value={newShortURL} onChange={(e) => setNewShortURL(e.target.value)} placeholder="Enter customized short URL" required />
                <input className="focus:outline-none focus:border-blue-500 align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 px-4 rounded-lg border border-green-500 text-green-500 hover:opacity-75 focus:ring focus:ring-green-200 active:opacity-[0.85] block w-full mt-4" type="submit" disabled={addShortMutation.isPending} value="Shorten" onClick={() => addShortMutation.mutate()} />
                {addShortMutation.error && <p className="w-full text-center text-red-500">{addShortMutation.error}</p>}
            </form>
            <hr />
            <section className="py-8">
                <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
                    {dataShorts.pages.flatMap(page => !page.data ? page : page.data).map((short, index) => (
                        <div className="relative flex flex-col bg-clip-border rounded-xl bg-white text-gray-700 shadow-md border border-blue-gray-100" key={`${short.id}`}>
                            <div className="relative bg-clip-border mt-4 mx-4 rounded-xl overflow-hidden bg-transparent text-gray-700 shadow-none !m-0 p-6">
                                <span className="tracking-normal font-sans text-lg font-semibold leading-snug text-gray-700 capitalize">Short ID:</span>
                                <span className="px-3 tracking-normal font-sans text-lg font-semibold leading-snug !text-red-500 break-all">{short.id}</span>
                                <p className="pt-2 pb-1 block font-sans leading-normal text-inherit font-normal !text-blue-500 break-all"><NavLink to={`/${short.id}`}>{short.url}</NavLink></p>
                                <p className="py-1 block font-sans leading-normal text-inherit font-normal !text-yellow-500 break-all">Created by: {isPendingUsers ? "Loading..." : dataUsers.find(user => user.id === short.uuid).firstName}</p>
                                <button className="align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 px-4 rounded-lg border border-red-500 text-red-500 hover:opacity-75 focus:ring focus:ring-red-200 active:opacity-[0.85] block w-full mt-4" disabled={deleteShortMutation.isPending} onClick={() => { deleteShortMutation.mutate(short.id) }}>Delete</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>
            <div ref={ref}>
                {isFetchingNextPage
                    ? 'Loading more...'
                    : hasNextPage
                        ? 'Load next page'
                        : 'Nothing left to load...'}
            </div>
            {/* 
            <button disabled={!dataShorts.prev} onClick={() => setPage(dataShorts.prev)}>Previous</button>
            <button disabled={!dataShorts.next} onClick={() => setPage(dataShorts.next)}>Next</button> 
            */}
        </div>
    )
}


export default ShortURL;