import { initializeApp } from "firebase/app";
import { getFirestore, collection, setDoc, getDocs, where, query, deleteDoc, doc, limit, startAfter, updateDoc, orderBy } from "firebase/firestore";
// import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCzDCazf_b_J4uhzqoNnEvbJAk8y-VK0Bw",
  authDomain: "short-e91c6.firebaseapp.com",
  projectId: "short-e91c6",
  storageBucket: "short-e91c6.appspot.com",
  messagingSenderId: "506514925807",
  appId: "1:506514925807:web:3database7ac546ca1f0c9d2531a",
  measurementId: "G-FPGWRCT2LT"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
const auth = getAuth(app);
const db = getFirestore(app);

const makeRequest = async (database, collectionName, method, data = {}) => {
  if (!database) { return Promise.reject('database is required') }
  if (!collectionName) { return Promise.reject('collection is required') }
  if (!method) { return Promise.reject('method is required') }
  // if (!data) { throw Error('data is required') }

  // console.log("data:", collectionName, data) 

  // GET Firebase
  if (method === "GET") {
    try {
      let snapshots
      if (Object.keys(data).length === 0) { snapshots = await getDocs(collection(database, collectionName)) }
      if (data._page === 1) { snapshots = await getDocs(query(collection(database, collectionName), orderBy('url', 'asc'), limit(data._per_page))) }
      if (data._page > 1) { snapshots = await getDocs(query(collection(database, collectionName), orderBy('url', 'asc'), startAfter(data.data.pages[data._page - 2].lastDocument), limit(data._per_page))) }
      if (data.id) {
        snapshots = await getDocs(query(collection(database, collectionName), where('id', '==', data.id), limit(1)))
        // console.log("snapshots:", snapshots)
        if (snapshots.empty) {
          return Promise.reject('No document found with that ID')
        }
      }

      const snapshotData = snapshots.docs.map(doc => doc.data());

      // if reached the end of the documents
      if (snapshots.docs.length < data._per_page) {
        snapshotData["next"] = undefined;
        // snapshots["prev"] = previousPage === 0 ? null : previousPage;
        return snapshotData
      }

      // if not reached the end of the documents
      const lastDocument = snapshots.docs[snapshots.docs.length - 1]
      snapshotData["lastDocument"] = lastDocument;
      // snapshots["prev"] = data["_page"] - 1 === 0 ? null : data["_page"] - 1;
      snapshotData["next"] = data._page + 1;
      return snapshotData
    } catch (error) {
      console.log("Error getting documents: ", error);
    }
  }


  // POST Firebase
  if (method === "POST") {
    try {
      const docRef = await setDoc(doc(database, collectionName, data.id), data);
    } catch (error) {
      console.error("Error adding document: ", error);
    }
  }

  // DELETE Firebase
  if (method === "DELETE") {
    try {
      const docRef = await deleteDoc(doc(database, collectionName, data.id))
    } catch (error) {
      console.error("Error deleting document: ", error)
    }
  }

  // UPDATE Firebase
  if (method === "PUT") {
    try {
      const docRef = doc(database, collectionName, "data.id")
      await updateDoc(docRef, data)
      console.log("Document updated with ID: ", "data.id")
    } catch (error) {
      console.error("Error updating document: ", error)
    }
  }
}


export { auth, db, makeRequest }