import React, { useState } from "react";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "../api/firebase";
import { useNavigate } from "react-router-dom";

const Signin = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            navigate("/");
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="px-8 py-8">
            <h1 className="block antialiased tracking-normal font-sans text-xl font-semibold leading-snug text-blue-gray-900 capitalize py-3">Sign in</h1>
            <form onSubmit={handleSubmit}>
                <label htmlFor="email">Email address:</label>
                <input className="w-full my-2 py-3 border-2 border-green-300 rounded-lg text-gray-700 focus:outline-none focus:border-blue-300"
                    type="email"
                    id="email"
                    aria-describedby="emailHelp"
                    placeholder="Enter email"
                    value={email}
                    autoComplete="email"
                    onChange={(e) => setEmail(e.target.value)}
                />
                <label htmlFor="password">Password:</label>
                <input
                    className="w-full my-2 py-3 border-2 border-green-300 rounded-lg text-gray-700 focus:outline-none focus:border-blue-300"
                    type="password"
                    id="password"
                    placeholder="Password"
                    value={password}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                />
                <button className="focus:outline-none focus:border-blue-500 align-middle select-none font-sans font-bold text-center uppercase transition-all disabled:opacity-50 disabled:shadow-none disabled:pointer-events-none py-2 px-4 rounded-lg border border-green-500 text-green-500 hover:opacity-75 focus:ring focus:ring-green-200 active:opacity-[0.85] block w-full mt-4" type="submit">
                    Login
                </button>
            </form>
        </div>
    );
};

export default Signin;