import { NavLink, Outlet } from "react-router-dom";

export default function DashboardLayout() {
    return (
        <div className="dashboard-layout">
            <h1>Welcome to Dashboard</h1>
            <nav>
                <NavLink to="setting">Setting</NavLink>
            </nav>
            <Outlet />
        </div>
    )
}
