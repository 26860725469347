import { NavLink, Outlet } from "react-router-dom";
import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Transition } from '@headlessui/react'

// i18n language support
import { useTranslation } from "react-i18next";
import { changeLanguage, currentLanguage } from "../i18next";

// firebase auth
// import { useFirebaseAuth } from "../context/FirebaseAuthContext";

export default function RootLayout() {
    const { t } = useTranslation()

    const languages = [
        { id: "en", name: 'English' },
        { id: "ja", name: '日本語' },
        { id: "zh-CHT", name: '繁體中文' },
        { id: "zh-CHS", name: '简体中文' }
    ]

    // const user = useFirebaseAuth();
    // console.log("RootLayout Auth:", user);

    return (
        <div className="root-layout">
            <header>
                <div className="flex justify-between items-center">
                    <div className="flex justify-start">
                        <h1 className="text-3xl font-bold flex-start">SNOWAI</h1>
                    </div>
                    <div className="flex justify-end">
                        <Listbox onChange={(language) => changeLanguage(language)}>
                            <ListboxButton className='relative block font-bold'>{t("language")}</ListboxButton>
                            <ListboxOptions className="absolute mt-1 text-base bg-blue-200 rounded-md" anchor="bottom">
                                {languages.map((language) => (
                                    <ListboxOption key={language.id} value={language.id} className="data-[focus]:bg-blue-300 data-[focus]:text-white">
                                        {language.name}
                                    </ListboxOption>
                                ))}
                            </ListboxOptions>
                        </Listbox>
                    </div>
                </div>
                <nav className="navbar flex justify-between items-center bg-blue-300 text-white p-4 font-bold">
                    <NavLink to="/">{t("nav.home")}</NavLink>
                    <NavLink to="/signin">{t("nav.signin")}</NavLink>
                    <NavLink to="/shorturl">{t("nav.shorturl")}</NavLink>
                    <NavLink to="/dashboard">{t("nav.dashboard")}</NavLink>
                </nav>
            </header>
            <main>
                <Outlet />
            </main>
        </div >
    )
}
