import { useRouteError } from "react-router-dom"

const RedirectError = () => {
    const error = useRouteError()

    return (
        <div>
            <h1>Something went wrong here - {error.message}</h1>
        </div>
    )
}

export default RedirectError;