import { useEffect } from 'react';
import { useLoaderData } from "react-router-dom";

// Firebase
import { db, makeRequest } from '../api/firebase';
const collectionShorts = "shorts"
// const collectionUsers = "users"
// const perPage = 8

// REST API
// import { makeRequest } from '../components/restAPI';
// const db = `http://192.168.88.111:4000`
// const collectionShorts = "shorts"
// const collectionUsers = "users"
// const perPage = 8

const Redirect = () => {
    const data = useLoaderData()

    useEffect((item) => {
        if (!data[0].url !== undefined) {
            window.location.href = data[0].url;
        }
    }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts    

    return (
        <div>
            {data.map(item => (
                <li key={item.id}>
                    Redirecting to . . . {item.url}
                </li>
            ))}
        </div>
    )
}


// Loader Function
export const RedirectLoader = async ({ params }) => {
    console.log(params)
    return makeRequest(db, collectionShorts, 'GET', params)
}

export default Redirect;