import { createContext, useEffect, useState } from "react";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from '../api/firebase';

const FirebaseAuthContext = createContext();

const FirebaseAuthProvider = ({ children }) => {
    const [user, setUser] = useState();
    const values = { "user": user, "setUser": setUser }

    useEffect(() => {
        // Listener to check authentication state
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                // User is signed in, see docs for a list of available properties
                setUser(user);
                // console.log("Firebase Auth Context:", user);
            } else {
                setUser(null);
                // User is not signed in
                // console.log('Firebase Auth Context: User is not signed in.');
            }
            return unsubscribe;
        });
    }, []);

    return (
        <FirebaseAuthContext.Provider value={values}>
            {children}
        </FirebaseAuthContext.Provider>
    );
};

export { FirebaseAuthProvider, FirebaseAuthContext };