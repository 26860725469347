import React from 'react';
import { createBrowserRouter, Route, createRoutesFromElements, RouterProvider } from 'react-router-dom';

// pages
import Home from './pages/Home';
import Register from './pages/Register';
import Signin from './pages/Signin';
import Redirect, { RedirectLoader } from './pages/Redirect';
import RedirectError from './pages/RedirectError';
import NotFound from './pages/NotFound';
import Protected from './pages/Protected';

// pages > dashboard
import ShortURLInfiniteCombined from './pages/ShortURLInfiniteCombined';
import Setting from './pages/dashboard/Setting';

// layouts
import RootLayout from './layouts/RootLayout';
import DashboardLayout from './layouts/DashboardLayout';

// context
import { FirebaseAuthProvider } from './context/FirebaseAuthContext';

// react query
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false, // default: true
    },
  },
});

// react router
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route index element={<Home />} />
      <Route path="register" element={<Register />} />
      <Route path="signin" element={<Signin />} />
      <Route path="shorturl" element={<Protected><ShortURLInfiniteCombined /></Protected>} />
      <Route path="dashboard" element={<Protected><DashboardLayout /></Protected>}>
        <Route path="setting" element={<Setting />} />
      </Route >
      <Route path=":id" element={<Redirect />} loader={RedirectLoader} errorElement={<RedirectError />} />
      <Route path="*" element={<NotFound />} />
    </Route >
  )
);

// app
function App() {
  return (
    <FirebaseAuthProvider>
      <QueryClientProvider client={queryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools />
      </QueryClientProvider>
    </FirebaseAuthProvider>
  );
}

export default App;
