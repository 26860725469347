// i18n language support
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

const currentLanguage = localStorage.getItem("language")

i18n.use(Backend).use(initReactI18next).init({
    lng: currentLanguage,
    fallbackLng: {
        default: [navigator.language, "en"],
        'zh-TW': ['zh-CHT'],
        'zh-HK': ['zh-CHT'],
        'zh-MO': ['zh-CHT'],
        'zh-SG': ['zh-CHT'],
        'zh-CN': ['zh-CHS']
    },
    debug: false,
    interpolation: {
        escapeValue: false // not needed for react as it escapes by default
    },
    Backend: {
        loadPath: "./locales/{{lng}}/{{ns}}.json"
    }
});

const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem("language", lng)
}

export { i18n, changeLanguage, currentLanguage };
